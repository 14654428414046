import { render, staticRenderFns } from "./ButtonArchiverInscription.vue?vue&type=template&id=53f610e2&scoped=true&"
import script from "./ButtonArchiverInscription.vue?vue&type=script&lang=js&"
export * from "./ButtonArchiverInscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f610e2",
  null
  
)

export default component.exports