<template>
  <div class="">
    <b-button
      variant="primary"
      @click="validateInscription"
    >
      <b-spinner
        v-if="isValdatingEts"
        small
        class="mr-1"
      />
      Valider cette inscription
    </b-button>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import ListAgentsComponent from './agents/components/ListAgentsComponent.vue'

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
    setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ListAgentsComponent,
    BBadge,
    ToastificationContent,
  },
  props: {
    inputDatas: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // etsDetails: null,
      etsDetails: JSON.parse(JSON.stringify(this.inputDatas)),
      etablissementId: null,
      isValdatingEts: false,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { etablissementId } = val.params
        this.etablissementId = etablissementId
        this.getEtablissement(etablissementId)
          .then(res => {
            this.etsDetails = res.data.resource
            console.log(this.etsDetails)
          })
          .catch(err => {})
      },
    },
  },

  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      getEtablissement: 'getEtablissement',
      createEtablissement: 'createEtablissement',
      updateEtablissement: 'updateEtablissement',
      action_validateInscriptionEtablissement: 'validateInscriptionEtablissement',
    }),
    validateInscription() {
      this.isValdatingEts = true
      this.action_validateInscriptionEtablissement({
        // eslint-disable-next-line no-underscore-dangle
        etablissementId: this.etsDetails._id,
        adminEtablissmentEmail:this.etsDetails.emailEtablissement,
      })
        .then(result => {
          console.log(result)
          this.$emit('onEtsValidationSuccess',result)
          this.isValdatingEts = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Mise à jour avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.isValdatingEts = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
