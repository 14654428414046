<template>
  <div class="d-flex flex-wrap gap-20">
    <FileWidget
      v-for="(fileItem, index) in files"
      :key="JSON.stringify(fileItem) + index"
      :file="fileItem"
    />
    <!-- <div class="file" /> -->
  </div>
</template>

<script>
import axios from 'axios'
import { BSpinner } from 'bootstrap-vue'
import FileWidget from '@/components/FileWidget.vue'

export default {
  components: {
    BSpinner,
    FileWidget,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDownloadingFile: false,
    }
  },
  methods: {
    getCloudinaryFileName(file) {
      return `${file.original_filename}.${file.format}`
    },
    async downlodFile(file) {
      this.isDownloadingFile = true
      const { url } = file
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: `${file.resource_type}/${file.format}` })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = this.getCloudinaryFileName(file)
          link.click()
          URL.revokeObjectURL(link.href)
          setTimeout(() => {
            this.isDownloadingFile = false
          }, 1000)
        })
        .catch(error => {
          this.isDownloadingFile = false
          console.error(error)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.files-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .file {
    padding: 10px 5px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 150px;
    border: 1px solid;
    position: relative;
    i {
      font-size: 80px;
    }
    .filename {
      width: 100%;
      /* white-space: nowrap; */
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .spinnerContainer {
      position: absolute;
      background: #00000080;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
    }
  }
}
</style>
